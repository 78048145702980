export default function authApi(axios, serverUrl) {
  return {
    login: async ({ email, password, token }) => {
      const { data: authResponse } = await axios({
        method: 'post',
        url: `${serverUrl}/api/auth/login`,
        data: {
          email,
          password,
          token,
        },
      });
      return authResponse;
    },
    getUserInformation: async () => {
      const { data } = await axios({
        method: 'get',
        url: `${serverUrl}/api/auth/user`,
        data: {},
      });
      return data;
    },
  };
}
