import React, { useMemo, useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { api } from '@bobolinkai/common';

import Loading from '../../../sharedComponents/Loading';
import SessionPlan from './SessionPlan';
import Navigation from './Navigation';
import ConfirmationModal from '../../../sharedComponents/ConfirmationModal';
import { CHAT_HISTORY_WINDOW_WIDTH_PX } from '../../../config';
import { useChatContext } from '../context.js';
import { useAuthContext } from '../../../state/providers/auth';

import './index.scss';

console.log('API object:', api);

function ChatHistoryWindow() {
  return (
    <div
      className="chat-history-window"
      style={{
        maxWidth: `${CHAT_HISTORY_WINDOW_WIDTH_PX}px`,
      }}
    >
      <Navigation />
      <ChatSessions />
    </div>
  );
}

function ChatSessions() {
  const {
    chatSession: activeChatSession,
    chatSessions,
    fetchingChatSessions: fetching,
    errorFetchingChatSessions: error,
    creatingChatSession,
    noSuchChatSession,
    setChatSessions,
  } = useChatContext();

  const { selectedOrganizationId } = useAuthContext();
  const navigate = useNavigate();

  const handleUpdateSessions = useCallback(async () => {
    if (!selectedOrganizationId) {
      console.error('No organization ID available');
      return;
    }
    try {
      const updatedSessions = await api.chat.session.getAll({ organizationId: selectedOrganizationId });
      if (typeof setChatSessions === 'function') {
        setChatSessions(updatedSessions);
      } else {
        console.error('setChatSessions is not a function:', setChatSessions);
      }
    } catch (error) {
      console.error('Error refreshing sessions:', error);
    }
  }, [selectedOrganizationId, setChatSessions]);

  const sortedChatSessions = useMemo(() => {
    if (!chatSessions) return [];
    return chatSessions
      .filter(session => !session.hidden)
      .sort((a, b) => {
        if (!a.lastMessageAt || !b.lastMessageAt) {
          if (moment(a.createdAt).isBefore(b.createdAt)) {
            return 1;
          }
          return -1;
        }
        if (moment(a.lastMessageAt).isBefore(b.lastMessageAt)) {
          return 1;
        }
        return -1;
      });
  }, [chatSessions]);

  useEffect(() => {
    if (activeChatSession && chatSessions) {
      const activeSession = chatSessions.find(s => s.id === activeChatSession.id);
      if (activeSession?.hidden) {
        navigate('/');
      }
    }
  }, [activeChatSession, chatSessions, navigate]);

  if ((fetching || !sortedChatSessions.length || (!activeChatSession && !noSuchChatSession)) && !creatingChatSession) {
    return null;
  }

  if (error) {
    return (
      <div>Error loading chat sessions</div>
    );
  }

  return (
    <div className="chat-sessions">
      {creatingChatSession && (
        <ChatSession
          key={'new-session'}
          isActive={true}
          title={'New Session'}
          chatSessions={chatSessions}
          onUpdateSessions={handleUpdateSessions}
        />
      )}

      {sortedChatSessions.map(chatSession => {
        const { id, title, sessionPlan } = chatSession;
        const isActiveChatSession = activeChatSession?.id === id;

        return (
          <ChatSession
            key={id}
            isActive={isActiveChatSession}
            title={title}
            sessionPlan={sessionPlan}
            chatSession={chatSession}
            chatSessions={chatSessions}
            onUpdateSessions={handleUpdateSessions}
            onClick={() => {
              if (!isActiveChatSession) {
                navigate(`/session/${id}`);
              }
            }}
          />
        );
      })}
    </div>
  );
}

function ChatSession({
  title,
  sessionPlan,
  isActive,
  onClick = () => {},
  chatSession,
  chatSessions,
  onUpdateSessions
}) {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const navigate = useNavigate();

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await api.chat.session.hide({ sessionId: chatSession.id });
      if (response.success) {
        if (typeof onUpdateSessions === 'function') {
          await onUpdateSessions();
          
          if (isActive) {
            navigate('/');
          }
        } else {
          console.error('onUpdateSessions is not a function:', onUpdateSessions);
        }
      }
    } catch (error) {
      console.error('Error hiding chat session:', error);
    }
    setShowDeleteConfirm(false);
  };

  return (
    <>
      <div
        className={`chat-session-container ${isActive ? 'active' : ''}`}
        onClick={onClick}
      >
        <div className="chat-session-header">
          <p className="chat-session-name">{title || 'Untitled'}</p>
          <IconButton
            size="small"
            onClick={handleDeleteClick}
            className="delete-button"
            aria-label="Delete chat session"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>

        {isActive && sessionPlan && (
          <SessionPlan chatSession={chatSession} />
        )}
      </div>

      <ConfirmationModal
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleConfirmDelete}
        title="Hide Chat Session"
        message="Are you sure you want to hide this chat session? You won't be able to see it in your history anymore."
        disableBackdropClick={true}
        keepMounted={false}
      />
    </>
  );
}

export default ChatHistoryWindow;
