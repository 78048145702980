import { useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';

function ConfirmationModal({ open, onClose, onConfirm, title, message }) {
  const previousActiveElement = useRef(null);

  useEffect(() => {
    if (open) {
      // Store the element that was focused before the dialog was opened
      previousActiveElement.current = document.activeElement;
    }
  }, [open]);

  const handleClose = () => {
    onClose();
    // Restore focus to the previously focused element
    if (previousActiveElement.current) {
      previousActiveElement.current.focus();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Typography id="confirmation-dialog-description">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal; 