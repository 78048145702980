import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';

function FormattedAuxiliaryContent({ markdownContent }) {
  const renderers = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter language={match[1]} PreTag="div" {...props}>
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    math({ value }) {
      return <BlockMath math={value} />;
    },
    inlineMath({ value }) {
      return <InlineMath math={value} />;
    },
  };

  return (
    <ReactMarkdown
      components={renderers}
    >
      {markdownContent}
    </ReactMarkdown>
  );
}

export default FormattedAuxiliaryContent;