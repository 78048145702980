import FormattedAuxiliaryContent from '../../../sharedComponents/FormattedAuxiliaryContent';
import { useChatContext } from '../context';
import Navigation from './Navigation';

import './index.scss';

function AuxiliaryPane() {
  const {
    hideAuxiliaryPane,
    auxContentToDisplay
  } = useChatContext();

  if (!auxContentToDisplay || hideAuxiliaryPane) {
    return null;
  }

  const { contents, title } = auxContentToDisplay;

  return (
    <div className="auxiliary-pane">
      <Navigation title={title} />
      <div className="auxiliary-pane-body">
        <div className="auxiliary-pane-body-contents">
          <FormattedAuxiliaryContent markdownContent={contents} />
        </div>
      </div>
    </div>
  );
}

export default AuxiliaryPane;