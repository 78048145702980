export default function authApi(axios, serverUrl) {
  return {
    createImage: async ({
      base64Image,
      fileName,
     }) => {
      const data = await axios({
        method: 'post',
        url: `${serverUrl}/api/user/image`,
        data: { base64Image, fileName },
        maxContentLength: Infinity, // Set to handle larger payloads
        maxBodyLength: Infinity, // To handle larger payloads as well
      });
      return data;
    },
  };
}
