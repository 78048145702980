import React, { createContext, useContext, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import moment from 'moment';
import { api } from '@bobolinkai/common';
import {
  // useLocation,
  useNavigate,
  useParams } from 'react-router-dom';
import { useAuthContext } from '../../state/providers/auth';

const sortMessages = messages => {
  return messages.sort((a, b) => {
    if (moment(a.createdAt).isAfter(b.createdAt)) {
      return 1;
    }
    return -1;
  })
};

const ChatContext = createContext();
export const ChatProvider = ({ children }) => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  // const location = useLocation();

  const { socket, selectedOrganizationId,user } = useAuthContext();

  const [chatSession, setChatSession] = useState(null);
  const [messages, setMessages] = useState([]);
  const [quickReplies, setQuickReplies] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [supportedLanguages, setSupportedLanguages] = useState(null);

  const [systemTypingForSessions, setSystemTypingForSessions] = useState({});

  const [auxiliaryContent, setAuxiliaryContent] = useState([]);
  const [initializing, setInitializing] = useState(true);
  const [showChatHistoryWindow, setShowChatHistoryWindow] = useState(true);

  const [hideAuxiliaryPane, setHideAuxiliaryPane] = useState(false);
  const [auxContentToDisplay, setAuxContentToDisplay] = useState(null);

  const [messageBody, setMessageBody] = useState('');
  const [lastUserMessage, setLastUserMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [messageFiles, setMessageFiles]=useState([]);
  const [chatSessions, setChatSessions] = useState(null);
  const [fetchingChatSessions, setFetchingChatSessions] = useState(true);
  const [errorFetchingChatSessions, setErrorFetchingChatSessions] = useState(null);

  const [overrideAuxiliaryContent, setOverrideAuxiliaryContent] = useState(null);

  const messageInputFieldRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const lastResponseInConversationWasUser = useMemo(() => {
    return messages[messages.length - 1]?.replyType === 'user';    
  }, [messages]);

  const submissionDisabled = !messageBody?.trim() || submitting;

  const inputDisabled = useMemo(() => {
    return initializing
      || submitting
      || lastResponseInConversationWasUser;
  }, [initializing, submitting, lastResponseInConversationWasUser]);

  const invalidSessionState = useMemo(() => {
    return lastResponseInConversationWasUser
      && !errorMessage
      && !submitting
      && !systemTypingForSessions[chatSession?.id];
  }, [
    lastResponseInConversationWasUser,
    errorMessage,
    systemTypingForSessions,
    chatSession,
    submitting,
  ]); 

  const [creatingChatSession, setCreatingChatSession] = useState(false);

  const [showAuditTrail, setShowAuditTrail] = useState(false);

  const [noSuchChatSession, setNoSuchChatSession] = useState(false);

  useEffect(() => {
    setFetchingChatSessions(true)
    api.chat.session.getAll({ organizationId: selectedOrganizationId })
      .then(chatSessions => {
        setChatSessions(chatSessions);
        setFetchingChatSessions(false);
      })
      .catch(err => {
        console.error(err);
        setErrorFetchingChatSessions('Unknown reason. Please try again later');
        setFetchingChatSessions(false);
      });

    if (!supportedLanguages) {
      api.chat.getSupportedLanguages()
        .then(supportedLanguages => {
          console.log('supported languages', supportedLanguages);
          setSupportedLanguages(supportedLanguages);
        });
    }
  }, []);

  useEffect(() => {
    if (chatSession?.error && !errorMessage) {
      console.log("::::Error here:::::",chatSession.error);
      setErrorMessage('Unknown error occurred');
    }
  }, [chatSession]);

  const handleMessageBodyChange = (event) => {
    setMessageBody(event.target.value);
  };

  // const getCurrentChatSession = async () => {
  //   let currentChatSession = await api.chat.session.getCurrent({ organizationId: selectedOrganizationId });
  //   console.log('got current chat session', currentChatSession);
  //   setChatSession(currentChatSession);
  //   console.log('chat session set');
  //   return currentChatSession;
  // };

  const getChatMessagesForSession = async sessionId => {
    // console.log('getting messages for chat session', sessionId);
    const messages = await api.chat.session.getMessages({ sessionId });
    console.log('got messages for chat session', messages);
    setMessages(sortMessages(messages));

    const userMessages =  messages.filter((m)=>m.replyType==="user");
    if(userMessages?.length)
    {
      setLastUserMessage(userMessages[userMessages.length-1].messageText);
    }
    
    const getQuickReplies = messages.filter((m)=>m.quickReplies);
    getQuickReplies.length && setQuickReplies(getQuickReplies[getQuickReplies.length-1].quickReplies);
  };

  const getAuxiliaryContentForSession = async sessionId => {
    console.log('Getting auxiliary content for session:', sessionId);
    const auxiliaryContent = await api.chat.session.getAuxiliaryContent({ sessionId });
    console.log('Fetched Auxiliary Content:', auxiliaryContent);
    setAuxiliaryContent(auxiliaryContent);
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result); // Resolve with Base64 string
      reader.onerror = reject; // Reject on error
      reader.readAsDataURL(file); // Read the file as a Data URL
    });
  }

  const createImage = async function(imageFile) {
    const base64Image = await convertToBase64(imageFile);
    const { data } = await api.user.createImage({ 
      base64Image,
      fileName: imageFile.name,
    });
    return data.imageId;
  };

  const submitUserMessage = async (message, chatSessionId = chatSession?.id) => {
    setSubmitting(true);
    setErrorMessage(null);

    let messageChatSessionId = chatSessionId;
    try {
      // upload images for message first
      // todo: upload these as they are selected instead of when the message is actually submitted
      console.log('uploading message files', messageFiles);
      const messageImages = await Promise.all(messageFiles.map(createImage));

      setLastUserMessage(message);
      if (!messageChatSessionId) {
        // console.log('chat sessions before creating chat session', chatSessions);
        const session = await api.chat.session.create({
          organizationId: selectedOrganizationId,
          language: selectedLanguage.code,
        });
        setChatSessions(chatSessions => [session, ...chatSessions]);
        messageChatSessionId = session.id;
        navigate(`/session/${messageChatSessionId}`);
        // console.log('chat sessions after creating chat session', [session, ...chatSessions]);
      }
      await api.chat.session.createMessage({
        sessionId: messageChatSessionId,
        text: message,
        images: messageImages,
      });

      setMessageFiles([]);
      setMessageBody('');
    }
    catch (err) {
      const message = err?.response?.data?.message;
      if (message && message === 'MAX CHARACTERS EXCEEDED') {
        // todo: handle specifically, perhaps...
      }
      setErrorMessage('An unknown error occurred while trying to send your message. Please refresh the page and try again.');
    }
    setSubmitting(false);
    focusInput();
  };

  const focusInput = () => {
    if (messageInputFieldRef.current) {
      console.log('focusing input', messageInputFieldRef);
      messageInputFieldRef.current.focus();
    }
    else {
      console.log('unable to focus input, setting timeout and trying again later');
      setTimeout(() => {
        focusInput();
      }, 100);
    }
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      console.log('scrolling to bottom');
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
    else {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }

  useEffect(() => {
    if (!inputDisabled) {
      focusInput();
    }
  }, [inputDisabled])

  useEffect(() => {
    scrollToBottom();
  }, [messages])

  useEffect(() => {
    function onMessage({ message, chatSessionId }) {
      console.log('socket message received', { message, chatSessionId });
      message.replyType==="system" && setQuickReplies(message.quickReplies||[]);
      setSystemTypingForSessions(typingForSessions => ({
        ...typingForSessions,
        [chatSessionId]: false,
      }));
      if (chatSessionId === chatSession?.id) {
        const messageAlreadyAdded = !!messages.find(m => m.id === message.id);
        if (!messageAlreadyAdded) {
          setMessages(messages => sortMessages([...messages, message]));
        }
      }
    }

    function onSystemTyping({ chatSessionId }) {
      setSystemTypingForSessions(typingForSessions => ({
        ...typingForSessions,
        [chatSessionId]: true,
      }));
    }

    function onModifiedChatSession({ chatSession: modifiedChatSession }) {
      console.log('update chatSession received', { modifiedChatSession });
      setChatSessions(chatSessions => {
        const index = chatSessions.findIndex(s => s.id === modifiedChatSession.id);
        const updatedChatSessions = [...chatSessions];
        updatedChatSessions.splice(index, 1, modifiedChatSession);
        return updatedChatSessions;
      });
      if (modifiedChatSession.id === chatSession.id) {
        setChatSession(modifiedChatSession);
      }
    }

    socket.on('modifiedChatSession', onModifiedChatSession);
    socket.on('message', onMessage);
    socket.on('system typing', onSystemTyping);

    return () => {
      socket.off('message', onMessage);
      socket.off('system typing', onSystemTyping);
      socket.off('modifiedChatSession', onModifiedChatSession);
    };
  }, [socket, chatSession]);

  useEffect(() => {
    if (systemTypingForSessions?.[chatSession?.id]) {
      scrollToBottom();
    }
  }, [systemTypingForSessions]);

  const setActiveChatSession = async (chatSession) => {
    setCreatingChatSession(false);
    if (!chatSession) {
      setNoSuchChatSession(true);
      setInitializing(false);
    }
    else {
      setNoSuchChatSession(false);
      setInitializing(true);
      setChatSession(chatSession);
      setAuxContentToDisplay(null);
      await getChatMessagesForSession(chatSession.id);
      await getAuxiliaryContentForSession(chatSession.id);
      setInitializing(false);
      focusInput();
    }
  };

  useEffect(() => {
    // // get most recently interacted with (by user) chat session
    // // then get the most recent chat messages for that chat session
    // getCurrentChatSession()
    //   .then((chatSession) => {
    //     return setActiveChatSession(chatSession);
    //   })

    // console.log('DETERMINING WHAT TO SET', {
    //   sessionId,
    //   chatSessions,
    //   chatSession,
    // });
    // get the chat session that is currently set in the url path
    if (
      sessionId
      && chatSessions?.length
      && (
        !chatSession
        || chatSession.id !== sessionId
      )
    ) {
      const session = chatSessions.find(s => s.id === sessionId);
      // console.log("SETTING ACTIVECHATSESSION");
      setActiveChatSession(session);
    }
    // if no session is set in the url path, show the create new session page
    else if (!sessionId && chatSession && !creatingChatSession) {
      // console.log("SETTING CREATECHATSESSION");
      createChatSession();
    }
    else if (!sessionId && !creatingChatSession) {
      // console.log("SETTING CREATECHATSESSION");
      createChatSession();
    }
  }, [sessionId, chatSessions]);

  const createChatSession = async () => {
    setNoSuchChatSession(false);
    setChatSession(null);
    setMessages([]);
    setCreatingChatSession(true);
    setAuxContentToDisplay(null);
    setInitializing(false);
    setQuickReplies([]);
  };

  const mostRecentUserMessage = useMemo(() => {
    if (!messages) return null;
    return messages.reduce((mostRecentUserMessage, message) => {
      if (!mostRecentUserMessage && message.replyType === 'system') return message;
      if (message.replyType !== 'system') return mostRecentUserMessage;
      if (moment(message.createdAt).isAfter(mostRecentUserMessage.createdAt)) {
        return message;
      }
      return mostRecentUserMessage;
    }, null);
  }, [messages]);

  useEffect(() => {
    if (mostRecentUserMessage) {
      setAuxContentToDisplay(mostRecentUserMessage.auxiliaryContent);
    }
  }, [mostRecentUserMessage]);

  useEffect(() => {
    if (overrideAuxiliaryContent) {
      setAuxContentToDisplay(overrideAuxiliaryContent);
    }
  }, [overrideAuxiliaryContent]);

  const getAndSetOverrideAuxContent = stepIndex => {
    // console.log('getting and setting override aux content', stepIndex);
    // console.log('messages', messages);

    const messageWithStepIndex = messages.find(message => {

      // console.log('message', message);
      // console.log('stepIndex', stepIndex);

      if (`[${message?.sessionPlanSnapshotIndex?.join(',')}]` === `[${stepIndex.join(',')}]`) {
        return true;
      }
    });

    // console.log('messageWithStepIndex', messageWithStepIndex);

    setOverrideAuxiliaryContent(messageWithStepIndex.auxiliaryContent);
  };

  const auxPaneIsVisible = !hideAuxiliaryPane && auxContentToDisplay;

  const context = {
    noSuchChatSession,
    creatingChatSession,
    auxPaneIsVisible,
    auxiliaryContent,
    errorMessage,
    initializing,
    showAuditTrail,
    invalidSessionState,
    chatSessions,
    submissionDisabled,
    fetchingChatSessions,
    errorFetchingChatSessions,
    auxContentToDisplay,
    selectedLanguage,
    supportedLanguages,
    setChatSessions,
    quickReplies,
    messages,
    messageBody,
    chatSession,
    systemTypingForSessions,
    inputDisabled,
    showChatHistoryWindow,
    hideAuxiliaryPane,
    messageInputFieldRef,
    messagesContainerRef,
    setShowAuditTrail,
    setMessageBody,
    lastUserMessage,
    setLastUserMessage,
    setAuxContentToDisplay,
    setActiveChatSession,
    createChatSession,
    submitUserMessage,
    setHideAuxiliaryPane,
    handleMessageBodyChange,
    setShowChatHistoryWindow,
    getAndSetOverrideAuxContent,
    setErrorMessage,
    setSelectedLanguage,
    setMessageFiles,
    messageFiles,
    setMessages,
  };

  console.log('chat context', context);
  return (
    <ChatContext.Provider value={ context }>
      { children }
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext)
  if (context === undefined) {
    throw new Error(
      'UseChatContext must be used within a ChatProvider',
    )
  }
  return context
}
