export default function chatSessionApi(axios, serverUrl) {
  return {
    getCurrent: async ({ organizationId }) => {
      const { data: { session } } = await axios({
        method: 'get',
        url: `${serverUrl}/api/chat/session/current`,
        params: { organizationId },
      });
      return session;
    },
    getAll: async ({ organizationId }) => {
      const { data: { sessions } } = await axios({
        method: 'get',
        url: `${serverUrl}/api/chat/session`,
        params: { organizationId },
      });
      return sessions;
    },
    create: async ({ organizationId, language }) => {
      const { data: { session } } = await axios({
        method: 'post',
        url: `${serverUrl}/api/chat/session`,
        data: { organizationId, language },
      });
      return session;
    },
    getMessages: async ({ sessionId }) => {
      const { data: { messages } } = await axios({
        method: 'get',
        url: `${serverUrl}/api/chat/session/${sessionId}/messages`,
      });
      return messages;
    },
    createMessage: async ({ sessionId, text, images }) => {
      const { data: { message } } = await axios({
        method: 'post',
        url: `${serverUrl}/api/chat/session/${sessionId}/message`,
        data: { text, images },
      });
      return message;
    },
    getAuxiliaryContent: async ({ sessionId }) => {
      const { data: { auxiliaryContents } } = await axios({
        method: 'get',
        url: `${serverUrl}/api/chat/session/${sessionId}/auxiliary-content`,
      });
      return auxiliaryContents;
    },
    getAuditTrail: async ({ sessionId }) => {
      const { data: { auditTrail } } = await axios({
        method: 'get',
        url: `${serverUrl}/api/chat/session/${sessionId}/audit-trail`,
      });
      return auditTrail;
    },
    addFeedback: async ({ messageId, feedback }) => {
      const { data } = await axios({
        method: 'post',
        url: `${serverUrl}/api/chat/message/feedback/${messageId}`,
        data: { feedback },
      });
      return data;
    },
    hide: async ({ sessionId }) => {
      const { data } = await axios({
        method: 'post',
        url: `${serverUrl}/api/chat/session/${sessionId}/hide`,
      });
      return data;
    },
  };
};
