import axios from 'axios';

import chatSessionRoutes from './chat/session';
import authRoutes from './auth';
import userRoutes from './user';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function axiosRequestInterceptor(config) {
  const token = localStorage.getItem('token');
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.authorization = `Bearer ${token}`;
  }
  return config;
}

function axiosRequestErrorInterceptor(error) {
  console.log('there was an error sending the axios request');
  console.error(error);
  return Promise.reject(error);
}

axios.interceptors.request.use(axiosRequestInterceptor, axiosRequestErrorInterceptor);

const api = {
  chat: {
    session: chatSessionRoutes(axios, SERVER_URL),
    getSupportedLanguages: async () => {
      const { data: { languages } } = await axios({
        method: 'get',
        url: `${SERVER_URL}/api/chat/supported-languages`,
        params: {},
      });
      return languages;
    },
  },
  auth: authRoutes(axios, SERVER_URL),
  user: userRoutes(axios, SERVER_URL),
};

console.log('API object:', api);
console.log('Chat session routes:', api.chat.session);

export default api;
